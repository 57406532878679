import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { brandRequest, medicinesRequests } from "../../services/requests";
import { api } from "../../services/api";
import { ButtonPrimary } from "../Buttons/ButtonPrimary";
import { ButtonSecondary } from "../Buttons/ButtonSecondary";
import { isAuthenticated } from "../../services/auth";
import { setMedicines } from "../../redux/actions/medicines";

const FilterProduct = () => {
  const { brands, requestStatus } = useSelector((state) => state.brandsReducer);

  const dispatch = useDispatch();

  const [url, setUrl] = useState("");

  const [listSpectrum] = useState([
    "Full Spectrum",
    "Isolado",
    "Broad Spectrum",
  ]);

  const [listPresentation] = useState([
    "Óleo",
    "Cápsula",
    "Tópico",
    "Spray",
    "Pasta",
  ]);

  const [selectedFilter, setSelectedFilter] = useState({
    selectBrand: "",
    selectConcentration: "",
    selectSpectrum: "",
    selectPresentation: "",
  });

  const setFilter = (e) => {
    // console.log(e.currentTarget);
    const { name, value } = e.currentTarget;
    setSelectedFilter((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const [filters, setFilters] = useState({ cbdmg: [] });

  const getFilters = async () => {
    const res = await api.get("/medicines/filters");
    const { cbdmg } = res.data;

    cbdmg.sort((a, b) => {
      if (parseInt(a, 10) < parseInt(b, 10)) return -1;
      if (parseInt(a, 10) > parseInt(b, 10)) return 1;
      return -1;
    });
    setFilters({ cbdmg });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      brandRequest(requestStatus, dispatch);
      getFilters();
    }
  }, []);

  useEffect(() => {
    const {
      selectConcentration,
      selectSpectrum,
      selectBrand,
      selectPresentation,
    } = selectedFilter;
    setUrl(
      `?name=&cbdmg=${selectConcentration && selectConcentration}&spectrum=${
        selectSpectrum && selectSpectrum
      }&type=${selectPresentation && selectPresentation}&brand=${
        selectBrand && selectBrand
      }`
    );
  }, [selectedFilter]);

  const clearSelectedFilters = () => {
    setSelectedFilter({
      selectBrand: "",
      selectConcentration: "",
      selectSpectrum: "",
      selectPresentation: "",
    });
  };

  return (
    <div className="bg-white border-top">
      <h4 className="text-black-50 text-center mt-2 font-black-0">Filtros</h4>
      <>
        <div className="form-floating">
          <select
            className="form-select"
            id="brandInput"
            name="selectBrand"
            aria-label="Floating label select example"
            onChange={(e) => {
              setFilter(e);
            }}
            value={selectedFilter.selectBrand}
          >
            <option value="">[Todas]</option>
            {Array.isArray(brands) &&
              brands.map((value) => (
                <option value={value.slug}>{value.name}</option>
              ))}
          </select>
          <label htmlFor="brandSelect">Marca</label>
        </div>
        <div className="form-floating">
          <select
            className="form-select"
            name="selectConcentration"
            id="concentrationSelect"
            aria-label="Floating label select example"
            onChange={(e) => {
              setFilter(e);
            }}
            value={selectedFilter.selectConcentration}
          >
            <option value="">[Todas]</option>
            {filters.cbdmg.map((filter) => (
              <option value={filter}>{filter}</option>
            ))}
          </select>
          <label htmlFor="concentrationSelect">Concentração</label>
        </div>
        <div className="form-floating">
          <select
            className="form-select"
            name="selectSpectrum"
            id="spectrumSelect"
            aria-label="Floating label select example"
            onChange={(e) => {
              setFilter(e);
            }}
            value={selectedFilter.selectSpectrum}
          >
            <option value="">[Todos]</option>
            {listSpectrum.map((spectrum) => (
              <option value={spectrum}>{spectrum}</option>
            ))}
          </select>
          <label htmlFor="spectrumSelect">Espectro</label>
        </div>
        <div className="form-floating">
          <select
            className="form-select"
            id="presentationSelect"
            name="selectPresentation"
            aria-label="Floating label select example"
            onChange={(e) => {
              setFilter(e);
            }}
            value={selectedFilter.selectPresentation}
          >
            <option value="">[Todas]</option>
            {listPresentation.map((presentation) => (
              <option value={presentation}>{presentation}</option>
            ))}
          </select>
          <label htmlFor="presentationSelect">Apresentação</label>
        </div>
      </>

      <div className="d-flex justify-content-between mx-2">
        <ButtonSecondary
          text="Limpar"
          width="w-50"
          callback={() => {
            clearSelectedFilters();
            dispatch(setMedicines({}, false));
          }}
        />
        <ButtonPrimary
          text="Filtrar Produtos"
          width="w-50"
          callBack={() => {
            medicinesRequests(false, dispatch, url);
          }}
          toggle="collapse"
          target="#toggleSearch"
          aria-expanded="false"
        />
      </div>
    </div>
  );
};

export default FilterProduct;
