import { getUri } from "../../services/uri";

const formatCalc = (calc) => {
  return calc.toFixed(2);
};

export const showTitleForHeader = (route) => {
  switch (route) {
    case getUri("/login"):
      return "Login";
    case getUri(""):
      return "Dr Cannabis";
    case getUri("/produtos"):
      return "Produtos";
    case getUri("/calculator"):
      return "Calculadora";
    default:
      return "Produto";
  }
};

export const round = (num, places) => {
  return +parseFloat(num).toFixed(places);
};

export const formatValue = (value, currency) => {
  switch (currency) {
    case "R$":
      return parseFloat(value).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    case "US$":
      return parseFloat(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    case "€":
      return parseFloat(value).toLocaleString("en-GB", {
        style: "currency",
        currency: "EUR",
      });
    default:
      return "";
  }
};

export default formatCalc;
