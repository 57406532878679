/* eslint-disable react/prop-types */
import React from "react";

const ButtonSecondary = ({
  text = "",
  btnClass = "",
  width = "w-100",
  callback,
}) => {
  return (
    <button
      className={`btn button-secondary font-weight-bold mt-3 ${btnClass} ${width}`}
      type="button"
      onClick={callback}
    >
      {text}
    </button>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { ButtonSecondary };
