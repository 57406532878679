import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatValue } from "../shared/utils/utils";
import { isAuthenticated } from "../services/auth";
import { getUri } from "../services/uri";

const Calculator = () => {
  const history = useHistory();

  const { medicine } = useSelector((state) => {
    return state.medicinesReducer;
  });

  const [getReady, setReady] = useState(false);

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push(getUri("/login"));
    }
  }, []);

  const resetCalculator = () => {
    return {
      totalAdministrations: 0,
      dailyDose: 0,
      doseInterval: 1,
      dailyVolume: 0,
      volumePerTake: 0,
      dropsPerTake: 0,
      treatmentDuration: 0,
      concentration: Math.ceil(medicine.cbdmg / medicine.content),
      bottleDuration: 0,
      requiredBottles: 0,
      monthlyPrice: 0,
      dropsPerMl: medicine.drops_per_ml,
      pricePerMg: (medicine.price / medicine.cbdmg).toFixed(3),
    };
  };

  const [dosage, setDosage] = useState(resetCalculator());

  const changeInput = (e) => {
    const { value, name } = e.currentTarget;
    setDosage((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (!dosage.dailyDose || !dosage.totalAdministrations) {
      return;
    }
    setDosage((prevState) => {
      const state = {
        ...prevState,
        dailyVolume: dosage.dailyDose / dosage.concentration,
        volumePerTake:
          dosage.dailyDose / dosage.concentration / dosage.totalAdministrations,
      };
      return state;
    });
  }, [dosage.dailyDose, dosage.totalAdministrations]);

  useEffect(() => {
    let aux = dosage.totalAdministrations < 1 ? 1 : dosage.totalAdministrations;

    aux = dosage.totalAdministrations > 6 ? 1 : dosage.totalAdministrations;

    setDosage((prevState) => {
      const state = {
        ...prevState,
        totalAdministrations: aux,
        doseInterval: 24 / aux,
      };
      return state;
    });
  }, [dosage.totalAdministrations]);

  useEffect(() => {
    const value = Math.ceil(dosage.volumePerTake * dosage.dropsPerMl);
    setDosage((prevState) => {
      return {
        ...prevState,
        dropsPerTake: Number.isNaN(value) ? 0 : value,
      };
    });
  }, [dosage.volumePerTake]);

  useEffect(() => {
    const value = Math.ceil(medicine.content / dosage.dailyVolume);
    setDosage((prevState) => {
      return {
        ...prevState,
        bottleDuration: Number.isNaN(value) || value === Infinity ? 0 : value,
      };
    });
  }, [dosage.dailyVolume]);

  useEffect(() => {
    const value = Math.ceil(
      (dosage.treatmentDuration * 30) / dosage.bottleDuration
    );
    setDosage((prevState) => {
      return {
        ...prevState,
        requiredBottles: Number.isNaN(value) ? 0 : value,
        monthlyPrice: (value * medicine.price) / dosage.treatmentDuration,
      };
    });
  }, [dosage.treatmentDuration, dosage.bottleDuration]);

  useEffect(() => {
    setReady(
      !Number.isNaN(dosage.dailyDose) &&
        dosage.dailyDose > 0 &&
        !Number.isNaN(dosage.totalAdministrations) &&
        !Number.isNaN(dosage.treatmentDuration) &&
        dosage.treatmentDuration > 0
    );
  }, [dosage.dailyDose, dosage.totalAdministrations, dosage.treatmentDuration]);

  return (
    <div className="calculator container">
      <div className="card">
        <div className="card-header card-header-synopsis">
          <h4 className="card-title card-title-synopsis">{medicine.name}</h4>
        </div>
        <div className="card-body card-body-synopsis">
          <h6 className="product-brand">{medicine.brand.name}</h6>
          <div className="row">
            <div className="col text-left">
              <h6 className="product-brand">{medicine.spectrum}</h6>
            </div>
            <div className="col text-right">
              <h6 className="product-brand">{medicine.type}</h6>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <h6 className="card-subtitle product-specs">
                CBD: {medicine.cbdmg} mg
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mt-1">
              <h6 className="card-subtitle product-specs">
                THC:&nbsp;
                {medicine.thc_complement && medicine.thc_complement}
                &nbsp;{medicine.thcp}%
              </h6>
            </div>
            <div className="col-6">
              <h6 className="card-subtitle product-content">
                {medicine.content} {medicine.content_type}
              </h6>
            </div>
          </div>
        </div>
        <div className="card-body calculator-input">
          <div className="row ml-0 mb-2 form-floating">
            <input
              type="number"
              className="form-control"
              name="dailyDose"
              id="dailyDose"
              value={dosage.dailyDose}
              onChange={(e) => changeInput(e)}
            />
            {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
            <label htmlFor="dailyDose">Dose total diária em miligramas</label>
          </div>
          <div className="row ml-0 mb-2 form-floating">
            <input
              type="number"
              className="form-control"
              name="totalAdministrations"
              id="totalAdministrations"
              min={1}
              max={4}
              value={dosage.totalAdministrations}
              onChange={(e) => changeInput(e)}
            />
            <label htmlFor="totalAdministrations">
              Em quantas aplicações diárias?
            </label>
          </div>
          <div className="row ml-0 mb-2 form-floating">
            <input
              type="number"
              className="form-control"
              name="treatmentDuration"
              id="treatmentDuration"
              value={dosage.treatmentDuration}
              onChange={(e) => changeInput(e)}
            />
            <label htmlFor="treatmentDuration">
              Duração do tratamento em meses
            </label>
          </div>
          {getReady ? (
            <>
              <div className="row py-2 ml-0 mb-2 alert alert-success">
                <h5 className="px-0 my-0 text-left">
                  Volume a ser aplicado a cada {dosage.doseInterval} horas
                </h5>
                <p className="px-0 my-0 text-left">
                  {dosage.volumePerTake.toFixed(1)} ml ou {dosage.dropsPerTake}{" "}
                  gotas
                </p>
              </div>
              <div className="row py-2 ml-0 mb-2 alert alert-success">
                <h5 className="px-0 my-0 text-left">
                  Quantidade de frascos necessários
                </h5>
                <p className="px-0 my-0 text-left">
                  {dosage.requiredBottles} frascos, um a cada{" "}
                  {dosage.bottleDuration} dias
                </p>
              </div>
              <div className="row py-2 ml-0 mb-2 alert alert-success">
                <h5 className="px-0 my-0 text-left">Custo do tratamento</h5>
                <p className="px-0 my-0 text-left">
                  {formatValue(dosage.monthlyPrice, medicine.currency)} por mês
                  <br />
                  Equivalentes a
                  {formatValue(dosage.pricePerMg, medicine.currency)} por mg
                </p>
              </div>
              <div className="col-12 d-flex justify-content-center mt-4">
                <button
                  type="button"
                  className="btn btn-outline-success font-weight-bold"
                  onClick={() => {
                    setDosage(resetCalculator());
                  }}
                >
                  <i className="fas fa-eraser" /> Reiniciar o cálculo
                </button>
              </div>
            </>
          ) : (
            <div className="row ml-0 mb-2 form-floating">
              <div type="button" className="alert alert-warning mt-3 mr-2">
                Por favor, preencha todos os dados
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calculator;
