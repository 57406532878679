import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { medicinesRequests } from "../services/requests";
import { formatValue } from "../shared/utils/utils";
import { getUri } from "../services/uri";
import { isAuthenticated } from "../services/auth";

const Products = () => {
  const { medicines, status } = useSelector((state) => state.medicinesReducer);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push(getUri("/login"));
    }
    medicinesRequests(status, dispatch);
  }, [status]);

  return (
    <div className="container">
      <h1 className="text-center">Lista de produtos</h1>
      {/* eslint-disable-next-line no-nested-ternary */}
      {Array.isArray(medicines.data) ? (
        medicines.data.length === 0 ? (
          // No records returned
          <h3 className="mt-5 text-center">
            Não há produtos com essas características.
            <p className="mt-4">Tente modificar seus critérios de filtro.</p>
          </h3>
        ) : (
          //  Records returned
          medicines.data.map((value) => {
            return (
              <div
                className="card card-synopsis"
                key={value.id}
                onClick={() => history.push(getUri(`/produto/${value.slug}`))}
                aria-hidden="true"
              >
                <div className="card-header card-header-synopsis">
                  <h4 className="card-title card-title-synopsis">
                    {value.name}
                  </h4>
                </div>
                <div className="card-body card-body-synopsis">
                  <h6 className="product-brand">{value?.brand.name}</h6>
                  <div className="row">
                    <div className="col text-left">
                      <h6 className="product-brand">{value?.spectrum}</h6>
                    </div>
                    <div className="col text-right">
                      <h6 className="product-brand">{value?.type}</h6>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <h6 className="card-subtitle product-specs">
                        CBD: {value.cbdmg} mg
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-1">
                      <h6 className="card-subtitle product-specs">
                        THC:&nbsp;
                        {value.thc_complement && value.thc_complement}
                        &nbsp;{value.thcp}%
                      </h6>
                    </div>
                    <div className="col-6">
                      <h6 className="card-subtitle product-content">
                        {value.content} {value.content_type}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="card-footer card-footer-synopsis">
                  <div className="row">
                    <div className="col card-footer-text-synopsis">
                      {formatValue(value.price, value.currency)}
                    </div>
                    <div className="col border-left card-footer-text-synopsis">
                      Saiba mais
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )
      ) : (
        //  Empty while processing request
        <div className="card card-synopsis" aria-hidden="true">
          <div className="card-header card-header-synopsis">
            <h4 className="card-title card-title-synopsis">
              <SkeletonTheme color="#72C7B6" highlightColor="#b6e3d8">
                <Skeleton />
              </SkeletonTheme>
            </h4>
          </div>
          <div className="card-body card-body-synopsis">
            <h6 className="product-brand">
              <Skeleton />
            </h6>
            <div className="row">
              <div className="col text-left">
                <h6 className="product-brand">
                  <Skeleton />
                </h6>
              </div>
              <div className="col text-right">
                <h6 className="product-brand">
                  <Skeleton />
                </h6>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <h6 className="card-subtitle product-specs">
                  <Skeleton />
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-1">
                <h6 className="card-subtitle product-specs">
                  <Skeleton />
                </h6>
              </div>
              <div className="col-6">
                <h6 className="card-subtitle product-content">
                  <Skeleton />
                </h6>
              </div>
            </div>
          </div>
          <div className="card-footer card-footer-synopsis">
            <div className="row">
              <div className="col card-footer-text-synopsis">
                <SkeletonTheme color="#72C7B6" highlightColor="#b6e3d8">
                  <Skeleton />
                </SkeletonTheme>
              </div>
              <div className="col border-left card-footer-text-synopsis">
                <SkeletonTheme color="#72C7B6" highlightColor="#b6e3d8">
                  <Skeleton />
                </SkeletonTheme>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { Products };
