import React from "react";
import { ToastContainer } from "react-toastify";
import { Routes } from "./routes";
import Header from "./components/Header/Header";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Header />
      <div className="content d-flex justify-content-center">
        <ToastContainer position="top-center" />
        <Routes />
      </div>
    </>
  );
}

export default App;
