import { combineReducers } from "redux";

import authReducer from "./auth";
import medicinesReducer from "./medicines";
import brandsReducer from "./brands";

// eslint-disable-next-line import/prefer-default-export
export const Reducers = combineReducers({
  authReducer,
  medicinesReducer,
  brandsReducer,
});
