/* eslint-disable */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonPrimary } from "../components/Buttons/ButtonPrimary";
import { ButtonSecondary } from "../components/Buttons/ButtonSecondary";
import { api } from "../services/api";
import { storeToken, getRole } from "../services/auth";
import { login } from "../redux/actions/login";
import { emailValidator, passwordIsStrong } from "../shared/utils/Validation";
import { getUri } from "../services/uri";

const submit = async (formState, callback, redirect) => {
  try {
    const res = await api.post("/login", formState);
    storeToken(res.data.token, res.data.session.roles[0], res.data.version);
    callback(login(res.data.session));
    redirect.push(getUri("/produtos"));
  } catch (e) {
    toast.error(e.response.data.errors);
  }
};

const Login = () => {
  const history = useHistory();
  const role = getRole();
  useEffect(() => {
    if (role) {
      history.push(getUri("/produtos"));
    }
  }, [role]);

  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [icon, setIcon] = useState("fa-eye-slash");

  const [touch, setTouch] = useState({
    password: false,
    email: false,
  });

  useEffect(() => {
    if (showPassword === true) {
      setIcon("fa-eye");
    } else {
      setIcon("fa-eye-slash");
    }
  }, [showPassword]);

  const changeInput = (e) => {
    const { name, value } = e.currentTarget;
    setFormState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const setTouchForm = (e) => {
    const { name } = e.currentTarget;
    setTouch((prevState) => {
      return {
        ...prevState,
        [name]: true,
      };
    });
  };

  return (
    <div className="card mt-4 border-radius-10 mx-2">
      <div className="card-header bg-transparent border-0">
        <h1>Login</h1>
        <span>Preencha os campos com as suas informações</span>
      </div>
      <div className="card-body">
        <form>
          <div className="form-group">
            <label htmlFor="email" className="text-secondary">
              Email
            </label>
            <input
              type="email"
              onClick={(e) => setTouchForm(e)}
              className={`form-control border-secondary ${
                touch.email &&
                !emailValidator(formState.email) &&
                "border-danger"
              }`}
              id="email"
              name="email"
              placeholder="name@example.com"
              value={formState.email}
              onChange={(e) => changeInput(e)}
            />
          </div>
          <div className="form-group mt-2 mb-1">
            <label className="text-secondary" htmlFor="password">
              Senha
            </label>
            <div className="input-group">
              <input
                type={`${showPassword ? "text" : "password"}`}
                name="password"
                onClick={(e) => setTouchForm(e)}
                className={`form-control border-right-0 ${
                  touch.password
                    ? passwordIsStrong(formState.password)
                      ? "border-secondary"
                      : "border-danger"
                    : "border-secondary"
                }`}
                id="password"
                placeholder="Password"
                value={formState.password}
                onChange={(e) => changeInput(e)}
              />
              <div className="input-group-append">
                <div
                  className={`input-group-text h-100 border-right pointer ${
                    touch.password
                      ? passwordIsStrong(formState.password)
                        ? "border-secondary"
                        : "border-danger"
                      : "border-secondary"
                  }`}
                >
                  <button
                    onClick={() => setShowPassword(!showPassword)}
                    type="button"
                    className="btn"
                  >
                    <i className={`fa ${icon}`} aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <a href="https://drcannabis.com.br/login#" target="_blank" className="text-third">
              Esqueci minha senha
            </a>
          </div>
          <ButtonPrimary
            btnClass="mb-0"
            callBack={() => submit(formState, dispatch, history)}
          />
          <ButtonSecondary text="Ainda não tenho uma conta" callback={() => window.open('https://drcannabis.com.br/cadastro?r=medico')}/>
        </form>
      </div>
    </div>
  );
};

export { Login };
