import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as $ from "jquery";
import { showTitleForHeader } from "../../shared/utils/utils";
import { isAuthenticated } from "../../services/auth";
import FilterProduct from "../FilterProduct/FilterProduct";
import { setMedicines } from "../../redux/actions/medicines";
import headerImage from "../../assets/images/drc-logo-header.png";
import { getUri } from "../../services/uri";

const Header = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("Dr Cannabis");
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setTitle(showTitleForHeader(location.pathname));
    setAuthenticated(isAuthenticated());
  }, [window.location.pathname, location.pathname]);

  const logout = () => {
    history.push(getUri("/logout"));
  };

  const produtos = () => {
    dispatch(setMedicines({}, false));
    history.push(getUri("/produtos"));
  };

  return (
    <div className="pos-f-t">
      <nav className="navbar navbar-dark fixed-top bg-white">
        <div className="container">
          {authenticated && (
            <div className="navbar-brand dropdown">
              <button
                className="dropdown-toggle border-0 background-transparent"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-bars text-body" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <button
                    type="button"
                    className="dropdown-item btn bg-transparent border-0 font-black-0 w-100 text-left"
                    onClick={() => logout()}
                    data-bs-toggle="collapse"
                    data-bs-target="#userMenu"
                    aria-expanded="true"
                  >
                    Logout
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="dropdown-item btn bg-transparent border-0 font-black-0 w-100 text-left"
                    onClick={() => produtos()}
                    data-bs-toggle="collapse"
                    data-bs-target="#userMenu"
                    aria-expanded="true"
                  >
                    Lista de produtos
                  </button>
                </li>
              </ul>
            </div>
          )}
          <div className="col">
            <img src={headerImage} alt="" className="mx-auto d-block" />
          </div>
          <div className="navbar-text">
            {title === "Produtos" && (
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#toggleSearch"
                aria-expanded="false"
                onClick={() => {
                  window.scrollTo(0, 0);
                  $("#userMenu").removeClass("show");
                }}
              >
                <i className="fas fa-search text-body" />
              </button>
            )}
            {(title === "Calculadora" || title === "Produto") && (
              <button
                className="button navbar-toggler"
                onClick={() => history.goBack()}
                type="button"
              >
                <i className="fas fa-arrow-left text-body" />
              </button>
            )}
          </div>
        </div>
      </nav>
      <div
        className={`collapse ${!authenticated && "d-none"}`}
        id="toggleSearch"
      >
        <FilterProduct />
      </div>
    </div>
  );
};

export default Header;
