/* eslint-disable react/prop-types */
import React from "react";

const ButtonPrimary = ({
  text = "Fazer login",
  btnClass = "",
  width = "w-100",
  callBack,
  disabled = false,
  controls,
  target,
  toggle,
}) => {
  return (
    <button
      className={`btn button-primary text-white font-weight-bold mt-3 ${btnClass} ${width}`}
      type="button"
      onClick={callBack}
      disabled={disabled}
      data-bs-toggle={toggle}
      data-bs-target={target}
      aria-controls={controls}
    >
      {text}
    </button>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { ButtonPrimary };
