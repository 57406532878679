import * as actionTypes from "./actionTypes";

const login = (data) => {
  return {
    type: actionTypes.SET_SESSION,
    payload: data,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { login };
