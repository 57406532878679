import { updateObject } from "../../shared/utils/UpdateObject";

const initialState = {
  medicines: {},
  medicine: {},
  status: false,
  medicineStatus: false,
};

const setMedicines = (state, action) => {
  return updateObject(state, {
    medicines: action.payload,
    status: action.status,
  });
};

const setMedicine = (state, action) => {
  return updateObject(state, {
    medicine: action.payload,
    medicineStatus: true,
  });
};

const setStatusRequest = (state, action) => {
  return updateObject(state, {
    status: action.payload,
  });
};

const medicinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MEDICINE_REQUEST_STATUS":
      return setStatusRequest(state, action);
    case "SET_MEDICINES":
      return setMedicines(state, action);
    case "SET_MEDICINE":
      return setMedicine(state, action);
    default:
      return state;
  }
};

export default medicinesReducer;
