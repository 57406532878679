import { api } from "./api";
import { setMedicine, setMedicines } from "../redux/actions/medicines";
import setBrands from "../redux/actions/brands";

const medicinesRequests = async (status = false, callback, url = "") => {
  if (!status) {
    const request = await api.get(`/medicines/${url && url}`);
    if (request.status === 200) {
      callback(setMedicines(request.data));
    }
  }
};

// eslint-disable-next-line consistent-return
const medicineRequest = async (callback, slug) => {
  if (!slug) return {};
  const request = await api.get(`/medicines/${slug}`);
  if (request.status === 200) {
    callback(setMedicine(request.data));
  }
};

const brandRequest = async (status = false, calback) => {
  if (!status) {
    const request = await api.get(`/brands`);
    if (request.status === 200) {
      calback(setBrands(request.data));
    }
  }
};

export { medicinesRequests, medicineRequest, brandRequest };
