import { updateObject } from "../../shared/utils/UpdateObject";

const initialState = {
  brands: {},
  requestStatus: false,
};

const setBrands = (state, action) => {
  return updateObject(state, {
    brands: action.payload,
    requestStatus: true,
  });
};

const brandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BRANDS":
      return setBrands(state, action);
    default:
      return state;
  }
};

export default brandsReducer;
