import { updateObject } from "../../shared/utils/UpdateObject";

const initialState = {
  session: {},
};

const setSession = (state, action) => {
  return updateObject(state, {
    session: action.payload,
  });
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SESSION":
      return setSession(state, action);
    default:
      return state;
  }
};

export default authReducer;
