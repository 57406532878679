export const TOKEN_KEY = "DRC-TOKEN";
export const ROLE = "DRC-ROLE";
export const VERSION = "DRC-VERSION";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRole = () => localStorage.getItem(ROLE);
export const getVersion = () => localStorage.getItem(VERSION);

export const storeToken = (token, role, version = 1) => {
  localStorage.setItem(`${TOKEN_KEY}`, token);
  localStorage.setItem(`${ROLE}`, role);
  localStorage.setItem(`${VERSION}`, version);
};

export const clearToken = () => {
  localStorage.clear();
};
