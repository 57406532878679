import axios from "axios";
import { clearToken, getToken } from "./auth";

const url = process.env.REACT_APP_API;
const debug = process.env.REACT_APP_API_DEBUG;

const api = axios.create({
  baseURL: url,
});

api.interceptors.request.use(async (requestConfig) => {
  const token = getToken();
  const config = requestConfig;
  if (token && !requestConfig.headers.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (debug === "1") {
    if (requestConfig.params) {
      config.params.XDEBUG_TRIGGER = 1;
    } else {
      config.params = { XDEBUG_TRIGGER: 1 };
    }
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      clearToken();
      window.location = `${window.origin}/pwa/login`;
    } else {
      throw error;
    }
  }
);

// eslint-disable-next-line import/prefer-default-export
export { api };
