import * as actionTypes from "./actionTypes";

const setBrands = (data) => {
  return {
    type: actionTypes.SET_BRANDS,
    payload: data,
  };
};

export default setBrands;
