import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { medicineRequest } from "../services/requests";
import { ButtonPrimary } from "../components/Buttons/ButtonPrimary";
import { formatValue } from "../shared/utils/utils";
import { getUri } from "../services/uri";
import { isAuthenticated } from "../services/auth";

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { medicine } = useSelector((state) => state.medicinesReducer);
  const { slug } = useParams();

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push(getUri("/login"));
    }
    medicineRequest(dispatch, slug);
  }, []);

  const calculationDataForDosage = () => {
    history.push(getUri("/calculator"));
  };

  return (
    <div className="container">
      {medicine && Object.values(medicine).length ? (
        <div className="card card-detail">
          <div className="card-header card-header-detail bg-white border-bottom-0 border-radius-10">
            <img
              src={medicine.photo?.uuidUrl}
              alt=""
              className="img-fluid mx-auto d-block"
            />
            <h4 className="card-title product-title">{medicine.name}</h4>
          </div>
          <div className="card-body card-body-detail">
            <h6 className="product-brand">{medicine?.brand.name}</h6>
            <div className="row">
              <div className="col text-left">
                <h6 className="product-brand">{medicine?.spectrum}</h6>
              </div>
              <div className="col text-right">
                <h6 className="product-brand">{medicine?.type}</h6>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <h6 className="card-subtitle product-specs">
                  CBD: {medicine.cbdmg} mg
                </h6>
              </div>
              <div className="col-12">
                <h6 className="card-subtitle product-specs">
                  THC:&nbsp;
                  {medicine.thc_complement && medicine.thc_complement}
                  &nbsp;{medicine.thcp}%
                </h6>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6 mt-1">
                <h6 className="card-subtitle product-price">
                  {formatValue(medicine.price, medicine.currency)}
                </h6>
              </div>
              <div className="col-6">
                <h6 className="card-subtitle product-content">
                  {medicine.content} {medicine.content_type}
                </h6>
              </div>
            </div>
            <div className="row mt-3">
              {medicine.show_calculator && (
                <ButtonPrimary
                  btnClass="border-radius-round"
                  text={
                    <>
                      <i className="fas fa-calculator pr-2" /> Calculadora
                    </>
                  }
                  callBack={() => calculationDataForDosage()}
                />
              )}
              {medicine.leaflet && Object.values(medicine.leaflet).length && (
                <a
                  href={medicine.leaflet.uuidUrl}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="link"
                  className="btn button-primary w-100 text-white font-weight-bold mt-3 border-radius-round mr-2"
                >
                  Certificado de Análise
                </a>
              )}
            </div>
            <div className="row mt-3">
              <div className="col product-text">{medicine.presentation}</div>
            </div>
            <div className="row mt-3">
              <div className="col product-text">{medicine.description}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card card-detail">
          <div className="card-header card-header-detail bg-white border-bottom-0 border-radius-10">
            <Skeleton height={349} />
            <h4 className="card-title product-title">
              <SkeletonTheme color="#72C7B6" highlightColor="#b6e3d8">
                <Skeleton />
              </SkeletonTheme>
            </h4>
          </div>
          <div className="card-body card-body-detail">
            <h6 className="product-brand">
              <Skeleton />
            </h6>
            <div className="row">
              <div className="col text-left">
                <h6 className="product-brand">
                  <Skeleton />
                </h6>
              </div>
              <div className="col text-right">
                <h6 className="product-brand">
                  <Skeleton />
                </h6>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <h6 className="card-subtitle product-specs">
                  <Skeleton />
                </h6>
              </div>
              <div className="col-12">
                <h6 className="card-subtitle product-specs">
                  <Skeleton />
                </h6>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6 mt-1">
                <h6 className="card-subtitle product-price">
                  <Skeleton />
                </h6>
              </div>
              <div className="col-6">
                <h6 className="card-subtitle product-content">
                  <Skeleton />
                </h6>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col product-text">
                <Skeleton count={2} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col product-text">
                <Skeleton count={5} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
