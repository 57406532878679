import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Login } from "./pages/Login";
// import { Home } from "./pages/Home";
import { clearToken } from "./services/auth";
import { Products } from "./pages/Products";
import Product from "./pages/Product";
import Calculator from "./pages/Calculator";
import { getUri } from "./services/uri";

const Routes = () => (
  <Switch>
    {/* <Route exact path={`${getUri("/home")}`} component={Home} /> */}
    <Route exact path={`${getUri("/login")}`} component={Login} />
    <Route exact path={`${getUri("/produtos")}`} component={Products} />
    <Route exact path={`${getUri("/produto/:slug")}`} component={Product} />
    <Route exact path={`${getUri("/calculator")}`} component={Calculator} />
    <Route
      path={`${getUri("/logout")}`}
      component={() => {
        clearToken();
        return <Redirect to={`${getUri("/login")}`} />;
      }}
    />
    {/* <Route path="*" component={Home} /> */}
    <Route path="*" component={Products} />
  </Switch>
);

// eslint-disable-next-line import/prefer-default-export
export { Routes };
