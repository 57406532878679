import * as actionTypes from "./actionTypes";

const setMedicineRequestStatus = (bool) => {
  return {
    type: actionTypes.SET_MEDICINE_REQUEST_STATUS,
    payload: bool,
  };
};

const setMedicines = (data, status = true) => {
  return {
    type: actionTypes.SET_MEDICINES,
    payload: data,
    status,
  };
};

const setMedicine = (data) => {
  return {
    type: actionTypes.SET_MEDICINE,
    payload: data,
  };
};

export { setMedicineRequestStatus, setMedicines, setMedicine };
