import { createStore } from "redux";
import storeSynchronize, { getState } from "redux-localstore";
import { Reducers } from "../reducers";

const persistedState = getState();

// eslint-disable-next-line import/prefer-default-export
export const Store = createStore(Reducers, persistedState);

storeSynchronize(Store);
