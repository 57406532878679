import React from "react";
import ReactDOM from "react-dom";

// eslint-disable-next-line no-unused-vars
import * as $ from "jquery";
import "bootstrap/dist/js/bootstrap.min";

// import core css
import "./index.css";

// import custom scss by DrCannabis
import "./assets/custom-css/main.scss";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import App from "./App";
import { Store } from "./redux/store/index";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
